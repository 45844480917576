// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  useEffect,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "@fas/cpa-cabinet-ui";
import en from "dayjs/locale/en-gb";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import { changeTableFilters } from "@fas/cpa-state-manager/redux/actions/table";
import { getTableData, getTableFilters, getTableTotalData } from "@fas/cpa-state-manager/services/selectors/table";
import {
  bindActionCreators,
  type Dispatch,
} from "redux";
import { connect } from "react-redux";
import { getDashboardDataSaga } from "@fas/cpa-state-manager/redux/actions/dashboard";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import { presets } from "../../helpers/generators";
import type {
  Props,
  OwnProps,
  StateToProps,
  DispatchToProps,
} from "./types/Dashboard.types";
import Chart from "../../components/Chart";
import { TableComponent } from "../../components/TableComponent";
import { formatDate } from "../../components/Chart/Chart";
import type { Column } from "../../components/TableComponent/types/TableComponent.types";
import { DASHBOARD_TABLE } from "../../helpers/constants";
import type { ItemData, TotalData } from "../../services/dashboardApi";

const mapStateToProps: (state: *) => StateToProps = (state) => ({
  data: getTableData(state, DASHBOARD_TABLE),
  // $FlowFixMe conflict type with lib
  dataTotal: getTableTotalData(state, DASHBOARD_TABLE),
  filters: getTableFilters(state, DASHBOARD_TABLE),
  loading: getLoadingState(state, "getDashboardDataLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetDashboardData: getDashboardDataSaga,
  onFiltersChange: (filters: Filters) => changeTableFilters(DASHBOARD_TABLE, filters),
}, dispatch);

const columns: Array<Column<ItemData>> = [
  {
    field: "date",
    label: "Date",
    key: "date",
    render: ({ row }) => (formatDate(row.date) === "Invalid Date" ? row.date : formatDate(row.date)),
  },
  {
    field: "quantity",
    label: "Leads",
    key: "quantity",
  },
  {
    field: "cost",
    label: "Average CPL, €",
    key: "cost",
    render: ({ row }) => `€${row.cost || 0}`,
  },
  {
    field: "amount",
    label: "Total Commission, €",
    key: "amount",
    render: ({ row }) => `€${row.amount || 0}`,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.mainBg,
    borderRadius: "15px",
  },
  title: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    // $FlowFixMe
    "-webkit-background-clip": "text",
    // $FlowFixMe
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
  },
  applyFiltersBtn: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    color: "#fff",
    fontWeight: "bold",
  },
  whiteBg: {
    color: "#fff",
  },
  dateRange: {
    color: "#fff",
    background: theme.palette.mainBg,
    "& textarea": {
      textAlign: "center",
    },
    "& *": {
      color: theme.palette.text.secondary,
    },
  },
  headerCell: {
    color: "rgba(255, 255, 255, 0.6)",
  },
  row: {
    background: theme.palette.mainBg,
    border: "none",
    color: "#fff",
    "& td:first-child": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    "& td:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  },
  cell: {
    color: "#fff",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 15px",
  },
  totalCell: {
    color: "rgba(255, 255, 255, 0.6)",
    background: "rgba(255, 255, 255, 0)",
  },
}));

const Dashboard: StatelessFunctionalComponent<Props> = ({
  data = [],
  dataTotal,
  filters,
  onGetDashboardData,
  onFiltersChange,
  loading,
}: Props) => {
  const classes = useStyles();

  const totalColumns: Array<Column<TotalData>> = [
    {
      field: "",
      label: "",
      key: "total",
      className: classes.totalCell,
      render: () => "Total",
    },
    {
      field: "quantity",
      label: "",
      key: "quantity",
      className: classes.totalCell,
    },
    {
      field: "cost",
      label: "",
      key: "cost",
      className: classes.totalCell,
      render: (): string => "-",
    },
    {
      field: "amount",
      label: "",
      sortable: true,
      key: "amount",
      className: classes.totalCell,
      render: ({ row }) => `€${row.amount || 0}`,
    },
  ];

  useEffect(() => {
    if (data.length === 0) {
      onGetDashboardData();
    }
  }, []);

  const isShowData: boolean = data.length > 0 && !loading;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" pb={2} pt={3} pl={2} pr={2}>
          <Typography variant="h5" className={classes.title}>Chart report</Typography>
          <Box display="flex">
            <DateRangePicker
              // $FlowFixMe filters type contains array but this must be string
              startDate={filters.dateFrom}
              // $FlowFixMe filters type contains array but this must be string
              endDate={filters.dateTo}
              onChangeDate={({ startDate, endDate }) => onFiltersChange({ dateFrom: startDate, dateTo: endDate })}
              onChangePreset={({ startDate, endDate }) => onFiltersChange({ dateFrom: startDate, dateTo: endDate })}
              presets={presets}
              locale={en}
              classes={{ input: { root: classes.dateRange }, dialog: {} }}
            />
            <Box pl={1}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={onGetDashboardData}
                className={classes.applyFiltersBtn}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
        { loading && (
          <Grid container justifyContent="center">
            <Box pb={3}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && (
          <Box>
            <Chart data={data} />
          </Box>
        )}
        { isShowData && (
          <Box mt={3} p={3}>
            <Box pb={2}>
              <Typography variant="h5" className={classes.title}>Table report</Typography>
            </Box>
            <TableComponent
              classes={{
                headerCell: classes.headerCell,
                row: classes.row,
                cell: classes.cell,
                table: classes.table,
              }}
              columns={columns}
              data={data}
              totalColumns={totalColumns}
              totalData={dataTotal}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Dashboard);
